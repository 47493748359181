@import url(https://fonts.googleapis.com/css?family=Montserrat:300,600);
body {
  font-family: "Montserrat", 'MontserratBold', Helvetica, Arial, sans-serif !important;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", 'MontserratBold', Helvetica, Arial, sans-serif !important;
}

.labelInformer {
  padding-right: 2.5% !important;
}

.tableInformer {
  margin-left: -1.5% !important;
}

body.login {
  background-color: #e9ecf3 !important;
}

.portlet .portlet-title .actions {
  padding: 0 !important;
}

.portlet .portlet-title .actions .portlet-reset {
  margin-left: 10px;
}

.portlet.light.bordered {
  border-color: rgba(59, 61, 63, 0.3) !important;
}

.portlet.light.bordered > .portlet-title {
  border-color: rgba(59, 61, 63, 0.3) !important;
}

.feedMaster.portlet.light.portlet-fit > .portlet-body {
  padding: 0 10px 10px !important;
}

.portlet.tableMaster div.portlet-title {
  margin: 0 !important;
}

.portlet.tableMaster div.portlet-body {
  padding: 0 !important;
}

.portlet.tableMaster div.portlet-body .dataTables_extended_wrapper {
  position: relative;
  margin-top: 0;
}

@media screen and (orientation: portrait) {
  .portlet.tableMaster div.portlet-body .dataTables_extended_wrapper {
    min-height: unset;
  }
}

.portlet.tableMaster div.portlet-body .dataTables_extended_wrapper div.table-scrollable {
  border: 0 !important;
  margin: 0 !important;
}

.portlet.tableMaster div.portlet-body .dataTables_extended_wrapper div.dataTables_paginate {
  position: absolute !important;
  top: -50px;
  right: 25px;
}

.portlet.tableMaster div.portlet-body .dataTables_extended_wrapper div.dataTables_paginate ul.pagination li:first-child {
  display: none;
}

.portlet.tableMaster div.portlet-body .dataTables_extended_wrapper div.dataTables_paginate ul.pagination li:last-child {
  display: none;
}

.portlet.tableMaster div.portlet-body .dataTables_extended_wrapper div.dataTables_paginate ul.pagination > li > a {
  border: none !important;
}

.portlet.tableMaster div.portlet-body .dataTables_extended_wrapper div.dataTables_paginate ul.pagination > .active > a {
  border-radius: 50% !important;
  border-color: #d35a2a !important;
  background-color: #d35a2a !important;
}

.portlet.tableMaster div.portlet-body .dataTables_extended_wrapper .table.dataTable {
  margin: 0 !important;
}

.portlet.currentMaster > .portlet-body {
  padding-top: 20% !important;
}

.portlet.currentMaster .currentField {
  width: 80%;
  margin: auto;
}

.portlet.currentMaster .currentField > img {
  float: left;
  width: 15%;
  padding-top: 3%;
}

.portlet.currentMaster .currentField > div {
  float: left;
  width: 83%;
  padding-left: 4%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.portlet.currentMaster .currentField > div > div:first-child {
  font-weight: 700;
  font-size: 3.5em;
  line-height: 85%;
}

.portlet.currentMaster .currentField > div > div:last-child {
  text-transform: uppercase;
  color: #aeaeae;
  font-size: 1.15em;
}

.feedMaster .feeds li {
  background-color: #fff !important;
}

.feedMaster .label-success {
  background-color: #48b0f7 !important;
}

.feedMaster .label-danger {
  background-color: #f55753 !important;
}

.feedMaster .label-primary {
  background-color: #6d5cae !important;
}

.feedMaster .label-warning {
  background-color: #f8d053 !important;
  color: #3b3d3f !important;
}

.feedMaster .label.label-sm:not(.md-skip) {
  font-size: 13px;
}

.feedMaster .feedTitle {
  float: left;
  line-height: 160%;
  padding-left: 10px;
}

.select2-container--bootstrap .select2-results__option[aria-disabled=true] {
  display: none;
}

.typeAheadFilter {
  width: 100%;
}

.todo-ui .badge {
  float: right;
}

.todo-ui .todo-project-list li {
  padding: 8px 0;
}

@media (max-width: 991px) {
  .todo-sidebar {
    position: inherit !important;
  }
  .todo-content {
    padding-left: 0 !important;
  }
}

/* ##### */
.icon-btn.disabled {
  pointer-events: none;
}

/* ##### */
.icon-btn.disabled, .icon-btn[disabled] {
  cursor: not-allowed;
  opacity: .65;
  filter: alpha(opacity=65);
}

/* ##### */
.icon-btn {
  margin: 10px 10px 0 0;
}

.icon-btn .badge.badge-left {
  left: -5px;
  right: inherit;
}

@media (max-width: 991px) and (min-width: 200px) {
  .paddized {
    margin-top: 100px !important;
  }
}
