.todo-ui {
  .badge {
    float: right;
  }
  .todo-project-list li {
    padding: 8px 0;
  }
}

@media (max-width: 991px) {
  .todo-sidebar {
    position: inherit !important;
  }
  .todo-content {
    padding-left: 0 !important;
  }
}