.portlet {
  .portlet-title {
    .actions {
      padding: 0 !important;
      .portlet-reset {
        margin-left: 10px;
      }
    }
  }
}

.portlet.light.bordered {
  border-color: $border-color !important;
  > .portlet-title {
    border-color: $border-color !important;
  }
}

.feedMaster.portlet.light.portlet-fit > .portlet-body {
  padding: 0 10px 10px !important;
}

.portlet.tableMaster {
  div.portlet-title {
    margin: 0 !important;
  }
  div.portlet-body {
    padding: 0 !important;
    .dataTables_extended_wrapper {
      position: relative;
      margin-top: 0;
      @media screen and (orientation: portrait) {
        min-height: unset;
      }
      div.table-scrollable {
        border: 0 !important;
        margin: 0 !important;
      }
      div.dataTables_paginate {
        position: absolute !important;
        top: -50px;
        right: 25px;
        ul.pagination {
          li:first-child {
            display: none;
          }
          li:last-child {
            display: none;
          }
          > li > a {
            border: none !important;
          }
          > .active > a {
            border-radius: 50% !important;
            border-color: $accent-color !important;
            background-color: $accent-color !important;
          }
        }
      }
      .table.dataTable {
        margin: 0 !important;
      }
    }
  }
}

.portlet.currentMaster {
  > .portlet-body {
    padding-top: 20% !important;
  }
  .currentField {
    width: 80%;
    margin: auto;
    > img {
      float: left;
      width: 15%;
      padding-top: 3%;
    }
    > div {
      float: left;
      width: 83%;
      padding-left: 4%;
      padding-top: 3%;
      padding-bottom: 3%;
      > div:first-child {
        font-weight: 700;
        font-size: 3.5em;
        line-height: 85%;
      }
      > div:last-child {
        text-transform: uppercase;
        color: $currentField-text-color;
        font-size: 1.15em;
      }
    }
  }
}