/* ##### */
.icon-btn.disabled {
  pointer-events: none;
}

/* ##### */
.icon-btn.disabled, .icon-btn[disabled] {
  cursor: not-allowed;
  opacity: .65;
  filter: alpha(opacity=65);
}

/* ##### */
.icon-btn {
  margin: 10px 10px 0 0;

  .badge.badge-left {
    left: -5px;
    right: inherit;
  }
}

@media (max-width: 991px) and (min-width: 200px) {
  .paddized {
    margin-top: 100px !important;
  }
}