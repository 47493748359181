.feedMaster {
  .feeds li {
    background-color: #fff !important;
  }
  .label-success {
    background-color: #48b0f7 !important;
  }
  .label-danger {
    background-color: #f55753 !important;
  }
  .label-primary {
    background-color: #6d5cae !important;
  }
  .label-warning {
    background-color: #f8d053 !important;
    color: #3b3d3f !important;
  }
  .label.label-sm:not(.md-skip) {
    font-size: 13px;
  }
  .feedTitle {
    float: left;
    line-height: 160%;
    padding-left: 10px;
  }
}