body {
  font-family: "Montserrat", 'MontserratBold', Helvetica, Arial, sans-serif !important;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", 'MontserratBold', Helvetica, Arial, sans-serif !important;
}

.labelInformer {
  padding-right: 2.5% !important;
}
.tableInformer{
  margin-left: -1.5% !important;
}